.main-btn {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: @color-black;
    background: @color-yellow;
    text-align: center;
    font-family: @main-font;
    font-size: @font-main;
    text-decoration: none;
    font-weight: @font-weight-bold;
    text-transform: uppercase;
    overflow: hidden;
    border: none;
    border-radius: 43px;
    overflow: hidden;
    cursor: pointer;
    transition: color .3s cubic-bezier(0.330, 0.975, 0.245, 0.910);

    > span {
        position: relative;
        z-index: 1;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: @color-black;
        transform: scaleX(0);
        opacity: 0;
        transform-origin: left;
        transition: transform .3s cubic-bezier(0.330, 0.975, 0.245, 0.910), opacity .3s cubic-bezier(0.330, 0.975, 0.245, 0.910);
    }

    &:hover {
        color: @color-white;

        &::after {
            transform: scaleX(1);
            opacity: 1;
        }
    }

    &.reverse {
        background: @color-black;
        color: @color-white;

        &::after {
            background: @color-yellow;
        }

        &:hover {
            color: @color-black;
        }
    }
}

.secondary-btn {
    position: relative;
    display: inline-block;
    padding: 10px 33px;
    color: @color-black;
    background: transparent;
    border: 2px solid @color-yellow;
    border-radius: 27px;
    text-align: center;
    font-family: @main-font;
    font-size: @font-m;
    font-weight: @font-weight-bold;
    text-transform: uppercase;
    overflow: hidden;
    cursor: pointer;
    transition: color .3s cubic-bezier(0.330, 0.975, 0.245, 0.910);

    > span {
        position: relative;
        z-index: 1;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: @color-yellow;
        transform: scaleX(0);
        opacity: 0;
        transform-origin: left;
        transition: transform .3s cubic-bezier(0.330, 0.975, 0.245, 0.910), opacity .3s cubic-bezier(0.330, 0.975, 0.245, 0.910);
    }

    &:hover {
        &::after {
            transform: scaleX(1);
            opacity: 1;
        }
    }
}

.btn-cart {
    .main-btn;

    .media-max(@screen-m, {
        font-size: @font-xxxs;
        padding: 3px 13px;
    });

    .media-min(@screen-m, {
        padding: 16px 61px 16px 34px;
        font-size: @font-main;

        &::before {
            .icon;
            .icon-cart;
            position: absolute;
            top: 50%;
            right: 14px;
            transform: translateY(-50%);
            color: @color-black;
            font-size: 25px;
            z-index: 1;
            transition: color .3s ease-in-out;
        }

        &:hover {
            &::before {
                color: @color-white;
            }
        }
    });

    &.added {
        pointer-events: none;

        &::after {
            transform: scaleX(0);
        }

        .added {
            transform: translateX(0);
        }
    }

    .added {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: @color-yellow;
        z-index: 2;
        transform: translateX(-100%);
        transition: transform .3s ease-in-out;
    }
}