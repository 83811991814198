//
//  Resolutions
//  ---------------------------------------------

@screen-xxs             : 375px;
@screen-xs              : 480px;
@screen-m               : 768px;
@screen-sl              : 920px;
@screen-l               : 1024px;
@screen-xl              : 1280px;
@screen-xxl             : 1440px;
@screen-xxxl            : 1920px;


//
//  Font-size
//  ---------------------------------------------

@font-family__base      : "Open Sans";
@main-font              : @font-family__base, Arial, sans-serif;


//
//  Font-size
//  ---------------------------------------------

@font-xxxl              : 26px;
@font-xxl               : 24px;
@font-xl                : 22px;
@font-l                 : 20px;
@font-m                 : 18px;
@font-xs                : 16px;
@font-main              : 14px;
@font-xxs               : 12px;
@font-xxxs              : 10px;


//
//  Font-weight
//  ---------------------------------------------

@font-weight-light      : 300;
@font-weight-regular    : 400;
@font-weight-semibold   : 600;
@font-weight-bold       : 700;


//
//  Color
//  ---------------------------------------------

@color-white            : #ffffff;
@color-border           : #e2e2e2;
@color-light            : #F3F3F3;
@color-main             : #333333;
@color-gray             : #262626;
@color-dark             : #141414;
@color-black            : #000000;
@color-yellow           : #FFE51E;
@color-blue             : #1691DC;
@color-darkblue         : #0D2D73;
@color-green            : #5DD270;
@color-red              : #FF1D1D;
@color-placeholder      : #767676;


//
//  Icons
//  ---------------------------------------------

@icon-filter: "\e91d";
@icon-facebook-1: "\e91b";
@icon-telegram: "\e91c";
@icon-sale: "\e91a";
@icon-arrow-left: "\e913";
@icon-arrow-right: "\e918";
@icon-cross: "\e919";
@icon-1: "\e900";
@icon-air-pump: "\e901";
@icon-arm: "\e902";
@icon-cart: "\e903";
@icon-chain: "\e904";
@icon-dislike: "\e905";
@icon-facebook: "\e906";
@icon-fist: "\e907";
@icon-heart: "\e908";
@icon-instagram: "\e909";
@icon-like: "\e90a";
@icon-login: "\e90b";
@icon-mail: "\e90c";
@icon-mountain: "\e90d";
@icon-recycle: "\e90e";
@icon-reply: "\e90f";
@icon-rss: "\e910";
@icon-search: "\e911";
@icon-star: "\e912";
@icon-tr: "\e914";
@icon-trash: "\e915";
@icon-twitter: "\e916";
@icon-youtube: "\e917";
@icon-checkmark: "\ea10";