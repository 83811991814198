input,
select,
textarea {
    padding: 0 15px;
    width: 100%;
    height: 34px;
    vertical-align: top;
    font: @font-weight-regular @font-main @main-font;
    background: @color-white;
    color: @color-main;
    border-radius: 8px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid @color-border;
    filter: none;

    // &.mage-error {
    //     border-color: @color_error;
    // }
}

a {
    color: @color-main;
    transition: color .3s ease-in-out;
}

input {
    &:-webkit-autofill {
        -webkit-box-shadow: inset 0 0 0 50px #fff;
        -webkit-text-fill-color: @color-main;
    }
}

select {
    padding: 0 40px 0 15px;
    background: @color-white url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDQ5MC42NzcgNDkwLjY3NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgY2xhc3M9IiI+PGcgdHJhbnNmb3JtPSJtYXRyaXgoMC45OCwwLDAsMC45OCw0LjkwNjc2NDk0NTk4MzY5Niw0LjkwNjc4MDE2NjYyNTkwNykiPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgoJPGc+CgkJPHBhdGggZD0iTTQ4OS4yNzIsMzcuMzM5Yy0xLjkyLTMuMzA3LTUuNDQtNS4zMzMtOS4yNTktNS4zMzNIMTAuNjhjLTMuODE5LDAtNy4zMzksMi4wMjctOS4yNTksNS4zMzMgICAgYy0xLjg5OSwzLjMwNy0xLjg5OSw3LjM2LDAuMDIxLDEwLjY2N2wyMzQuNjY3LDQwNS4zMzNjMS44OTksMy4zMDcsNS40MTksNS4zMzMsOS4yMzcsNS4zMzNzNy4zMzktMi4wMjcsOS4yMzctNS4zMzMgICAgTDQ4OS4yNTEsNDguMDA1QzQ5MS4xNDksNDQuNzIsNDkxLjE0OSw0MC42NDUsNDg5LjI3MiwzNy4zMzl6IiBmaWxsPSIjODA4MDgwIiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBzdHlsZT0iIiBjbGFzcz0iIj48L3BhdGg+Cgk8L2c+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPC9nPjwvc3ZnPg==);
    background-position: calc(~'100% - 21px') center;
    background-size: 10px;
    background-repeat: no-repeat;
    cursor: pointer;
}

label {
    display: block;
    margin-bottom: 4px;
    color: #979797;
    font-size: @font-xxxs;
    font-weight: @font-weight-bold;
    text-align: left;

    &.required {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;

        em {
            margin-left: 2px;
            color: @color-red;
        }
    }
}

textarea {
    height: 110px;
    padding: 15px;
    resize: none;
}

input, textarea {
    &::-webkit-input-placeholder {
        color: @color-placeholder;
    }

    &:-moz-placeholder { /* Firefox 18- */
        color: @color-placeholder;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
        color: @color-placeholder;
    }

    &:-ms-input-placeholder {
        color: @color-placeholder;
    }

    &:focus, &:active {
        &::-webkit-input-placeholder {
            color: @color-placeholder;
            font-size: 0;
        }

        &:-moz-placeholder { /* Firefox 18- */
            color: @color-placeholder;
            font-size: 0;
        }

        &::-moz-placeholder {  /* Firefox 19+ */
            color: @color-placeholder;
            font-size: 0;
        }

        &:-ms-input-placeholder {
            color: @color-placeholder;
            font-size: 0;
        }
    }
}

.control {
    position: relative;
}

input[type="radio"],
input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;

    & + label {
        position: relative;
        padding: 0 0 0 29px;
        font-size: @font-m;
        color: @color-main;
        font-weight: @font-weight-regular;
        cursor: pointer;

        &:after,
        &:before {
            content: '';
            position: absolute;
            box-sizing: border-box;
        }

        &:before {
            top: 4px;
            left: 0;
            width: 18px;
            height: 18px;
            background: @color-white;
            border-radius: 50%;
            border: 1px solid #E6E6E6;
        }

        &:after {
            top: 4px;
            left: 0;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            border: 5px solid @color-black;
            transform: scale(0);
            transition: transform .3s;
        }
    }

    &:checked + label {
        &:after {
            transform: scale(1);
        }
    }
}

input[type="checkbox"] {
    & + label {
        &:before {
            top: 4px;
            left: 0;
            width: 25px;
            height: 25px;
            border-radius: 8px;
        }

        &:after {
            .icon;
            .icon-checkmark;
            top: 2px;
            left: 0;
            width: auto;
            height: auto;
            color: @color-main;
            border-radius: unset;
            border: none;
            font-size: 28px;
        }
    }

    &:checked + label {
        &:after {
            transform: scale(1);
        }
    }
}

.control.toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
        margin: 0 10px 0 0;
        color: @color-main;
        font-size: @font-m;
        font-weight: @font-weight-bold;
    }

    .toggle-switch {
        margin: 0;
        text-align: left;

        input {
            & + .toggle {
                &::before {
                    right: 50%;
                }

                &::after {
                    left: 50%;
                }
            }

            &:checked + .toggle {
                background: @color-green;

                &::after {
                    opacity: 0;
                }

                .switch {
                    border-color: @color-green;
                }
            }

            &:not(:checked) + .toggle {
                background: #D25D5D;

                &::before {
                    opacity: 0;
                }

                .switch {
                    right: calc(~'100% - 30px');
                    border-color: #D25D5D;
                }
            }
        }

        .toggle {
            position: relative;
            display: block;
            width: 68px;
            height: 30px;
            border-radius: 50px;
            cursor: pointer;
            transition: background .3s ease-in-out;

            &::before,
            &::after {
                position: absolute;
                display: flex;
                align-items: center;
                height: 100%;
                color: @color-white;
                font-size: 13px;
                font-weight: @font-weight-semibold;
                transition: opacity .3s ease-in-out;
                z-index: 2;
            }

            &::before {
                content: 'ON';
            }

            &::after {
                content: 'OFF';
            }

            .switch {
                position: absolute;
                right: 0;
                height: 100%;
                width: 30px;
                background: @color-white;
                border: 3px solid transparent;
                border-radius: 50%;
                transition: right .3s ease-in-out, border-color .3s ease-in-out;
            }
        }
    }
}

.input-box {
    margin: 0 0 15px;
}

.form-list {
    li {
        margin: 0 0 15px;

        &.fields {
            margin: 0;
        }

        &.control {
            input[type=checkbox]+label {
                padding: 6px 0 0 40px;
                margin-bottom: 20px;
                text-align: left;
                font-size: @font-main;
            }
        }
    }
}

.data-table {
    width: 100%;

    th,
    td {
        text-align: left;
    }

    tr {
        th,
        td {
            .media-min(@screen-l, {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            });
        }
    }

    thead {
        .media-max(@screen-l, {
            display: none;
        });

        th {
            padding: 18px 8px;
            white-space: nowrap;
        }
    }

    tbody {
        td {
            padding: 13px 8px;

            .media-max(@screen-l, {
                display: block;
                padding: 5px 0;
                text-align: left !important;

                &:last-child {
                    padding-bottom: 30px;
                }

                &::before {
                    content: attr(data-th);
                    margin-right: 5px;
                    font-weight: @font-weight-bold;
                }
            });
        }
    }

    .first {
        padding-left: 0;
    }

    .last {
        padding-right: 0;
    }

    .a-right {
        text-align: right;
    }

    .a-center {
        text-align: center;
    }
}

.validation-advice {
    min-height: 13px;
    color: @color-red;
    margin: 6px 0 0;
    padding: 0 2px;
    font-size: @font-xxs;
    text-align: left;
}