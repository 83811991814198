// General
.std {
    line-height: 2;

    ul {
        li {
            line-height: unset;

            &::before {
                top: 10px;
            }
        }
    }

    .text-title-block {
        position: relative;
        font-size: @font-xxl;
        font-weight: @font-weight-bold;
        padding-bottom: 18px;
        margin-bottom: 10px;
        line-height: 1.3;

        .underline {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 62px;
            height: 7px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 41px;
                height: 7px;
                background: @color-yellow;
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 14px;
                height: 7px;
                background: @color-yellow;
            }
        }
    }
}

// Block with text & image

.product-image-text-widget {
    margin: 20px 0;

    .media-min(@screen-l, {
        margin: 40px 0;
    });

    .content-holder {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .media-min(@screen-m, {
            flex-direction: row;
        });
    }

    .image-block {
        width: 100%;
        padding-bottom: 10px;

        .media-max(@screen-m, {
            width: calc(~'100% + 36px');
            margin: 0 -18px;
        });

        img {
            display: block;
            margin: 0 auto;
        }
    }

    .text-block {
        width: 100%;
        padding-bottom: 10px;

        .text-wrap {
            height: 100%;
        }
    }

    .bottom-text-block {
        width: 100%;

        .media-min(@screen-m, {
            margin-top: 10px;
        });
    }

    &.full-width {
        .image-block {
            .media-min(@screen-m, {
                width: 100% !important;
            });
        }

        .text-block {
            .media-min(@screen-m, {
                width: 100% !important;
                padding-left: 0 !important;
                padding-right: 0 !important;
            });
        }
    }

    &.image-left {
        .image-block {
            .media-min(@screen-m, {
                width: 50%;
            });
        }

        .text-block {
            .media-min(@screen-m, {
                width: 50%;
                padding-left: 10px;
            });

            .media-min(@screen-l, {
                padding-left: 30px;
            });
        }
    }

    &.image-right {
        .image-block {
            .media-min(@screen-m, {
                width: 50%;
            });
        }

        .text-block {
            order: -1;

            .media-min(@screen-m, {
                width: 50%;
                padding-right: 10px;
            });

            .media-min(@screen-l, {
                padding-right: 30px;
            });
        }
    }

    &.image-left,
    &.image-right {
        &.image-30 {
            .image-block {
                .media-min(@screen-m, {
                    width: 33.333%;
                });
            }

            .text-block {
                .media-min(@screen-m, {
                    width: calc(~'100% - 33.333%');
                });
            }
        }

        &.image-70 {
            .image-block {
                .media-min(@screen-m, {
                    width: calc(~'100% - 33.333%');
                });
            }

            .text-block {
                .media-min(@screen-m, {
                    width: 33.333%;
                });
            }
        }
    }
}

// Block banner with image

.product-bottle-banner-widget {
    margin: 20px 0;

    .media-min(@screen-l, {
        margin: 40px 0;
    });

    .text-title-block {
        .media-min(@screen-m, {
            max-width: 490px;
        });

        .underline {
            &::before,
            &::after {
                background: @color-white;
            }
        }
    }

    .content-holder {
        padding: 37px 18px 0;
        color: @color-white;
        background-size: cover;
        background-repeat: no-repeat;

        .media-min(@screen-m, {
            padding: 40px 37px 0;
        });

        .media-max(@screen-xl, {
            width: calc(~'100% + 36px');
            margin: 0 -18px;
        });

        .description {
            font-weight: @font-weight-light;

            .media-min(@screen-m, {
                max-width: 490px;
            });
        }

        .bottom-title-wrap {
            width: calc(~'100% + 36px');
            margin: 210px -18px 0;
            padding: 0 27px 27px;

            .media-min(@screen-m, {
                position: relative;
                width: calc(~'100% + 74px');
                margin: 40px -37px 0;
                padding: 20px 37px;
            });

            img {
                display: block;
                margin: 0 auto -200px;
                transform: translateY(-50%);
                width: 169px;
                height: 413px;
                object-fit: contain;

                .media-min(@screen-m, {
                    position: absolute;
                    right: 70px;
                    bottom: 40%;
                    margin: 0;
                    transform: none;
                    object-position: bottom;
                });

                .media-min(@screen-l, {
                    right: 25%;
                    transform: translateX(50%);
                });
            }

            .bottom-title {
                margin: 0;
                font-size: 36px;
                font-weight: @font-weight-bold;
                line-height: 1.3;

                .media-min(@screen-m, {
                    max-width: 490px;
                    font-size: 48px;
                });
            }
        }
    }
}

// Product Slider widget

.product-slider-widget,
.product-slider-up-sell {
    margin: 20px 0;
    overflow: hidden;

    .media-min(@screen-l, {
        margin: 40px 0;
    });

    .block-title {
        margin-bottom: 16px;
        text-align: center;

        .media-min(@screen-m, {
            position: relative;
            margin-bottom: 30px;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 1px;
                background: @color-border;
                transform: translateY(-50%);
            }
        });

        .title {
            font-size: @font-m;
            font-weight: @font-weight-bold;
            text-transform: uppercase;

            .media-min(@screen-m, {
                display: inline-block;
                position: relative;
                font-size: @font-xxl;
                padding: 0 56px;
                background: @color-white;
            });
        }
    }

    .content-holder {
        margin: 0 -18px;

        .media-max(@screen-xs, {
            width: calc(~'100% + 36px');
        });

        .media-min(@screen-xs, {
            margin: 0 -10px;
        });

        .media-min(@screen-xxl, {
            margin: 0 -17px;
        });
    }

    .slick-track {
        display: flex;
        margin: 0;
    }

    .item {
        height: auto;
        line-height: 1.3;

        &:not(.slick-slide) {
            .media-max(@screen-xs, {
                margin: 0 auto;
            });
        }

        &.slick-slide {
            padding: 0 10px;

            .media-min(@screen-xxl, {
                padding: 0 17px;
            });
        }

        .item-wrap {
            display: flex;
            flex-direction: column;
            height: 100%;
            margin: 0 auto;
            max-width: 230px;
        }

        .product-image {
            margin-bottom: 15px;
        }

        .product-name {
            margin-bottom: 10px;
            padding: 0;
            font-size: @font-main;

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .product-info {
            display: flex;
            flex-direction: column;
            flex: 1 0 auto;
        }

        .price-box {
            display: flex;
            flex-direction: row-reverse;
            justify-content: start;
            margin-top: auto;

            p {
                margin-bottom: 0;
            }

            .price {
                font-size: @font-xxl;
            }

            .old-price {
                .price {
                    font-size: @font-xxs;
                }
            }

            .minimal-price-link {
                display: flex;
                flex-direction: column;
                text-decoration-line: none;
                margin-right: 15px;

                .price {
                    text-decoration: line-through;
                }
            }
        }

        .actions {
            margin-top: 15px;
        }

        .btn-cart {
            width: 100%;
            padding: 10px 20px;
            font-size: @font-main;

            .media-min(@screen-m, {
                padding: 11px 61px 11px 34px;
            });

            &::before {
                .icon;
                .icon-cart;
                position: absolute;
                top: 50%;
                right: 14px;
                transform: translateY(-50%);
                color: @color-black;
                font-size: 18px;
                z-index: 1;
                transition: color .3s ease-in-out;

                .media-min(@screen-m, {
                    right: 30px;
                });
            }

            &:hover {
                &::before {
                    color: @color-white;
                }
            }
        }
    }
}

.product-slider-up-sell {
    .content-holder {
        .media-min(@screen-xs, {
            padding: 0 10px;
        });

        .media-min(@screen-xl, {
            padding: 0;
        });
    }
}

.hp-best-sellers {
    .btn-cart {
        border-radius: 0;
    }
}