.qty-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    .td-title {
        margin-right: 10px;
        font-size: @font-main;
        font-weight: @font-weight-bold;
    }

    .qty {
        height: 47px;
        width: 49px;
        border-radius: 6px 0 0 6px;
        border: 1px solid #C4C4C4;
        border-right: none;
        font-size: @font-m;
        font-weight: @font-weight-bold;
        text-align: center;
        padding: 0 5px;
    }

    .qty-inner {
        height: 47px;
        display: flex;
        flex-direction: column;
        width: 30px;
        border-radius: 0 6px 6px 0;
        overflow: hidden;

        .qty-math {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50%;
            color: @color-white;
            background: @color-black;
            text-decoration: none;
            font-size: @font-xs;
            font-weight: @font-weight-bold;
            transition: color .3s ease-in-out, background .3s ease-in-out;

            &:hover {
                background: @color-yellow;
                color: @color-black;
            }
        }
    }
}

// Pages

.pager {
    .pages {
        padding: 11px 0 23px;

        .media-min(@screen-m, {
            padding-bottom: 41px;
        });

        ol {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 -3px;
        }

        li {
            padding: 0 4px;

            .media-min(@screen-xxs, {
                padding: 0 6px;
            });

            &.current {
                span {
                    background: @color-yellow;
                }
            }

            a:not(.previous):not(.next):not(.next_jump):not(.previous_jump),
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                border: 2px solid transparent;
                border-radius: 50%;
                color: @color-black;
                font-size: @font-main;
                font-weight: @font-weight-bold;
                transition: border-color .3s ease-in-out;
                background: #EDEDED;

                .media-min(@screen-xxs, {
                    width: 45px;
                    height: 45px;
                });

                .media-min(@screen-m, {
                    width: 50px;
                    height: 50px;
                    font-size: @font-xs;
                });
            }

            a:not(.previous):not(.next):not(.next_jump):not(.previous_jump) {
                &:hover {
                    border-color: @color-yellow;
                }
            }

            a {
                text-decoration: none;

                &.previous,
                &.next {
                    padding: 0 5px;

                    &::before {
                        .icon;
                        color: #D5D5D5;
                        font-size: 18px;
                        transition: color .3s ease-in-out;
                    }

                    &:hover {
                        &::before {
                            color: @color-yellow;
                        }
                    }
                }

                &.previous {
                    &::before {
                        .icon-arrow-left;
                    }
                }

                &.next {
                    &::before {
                        .icon-arrow-right;
                    }
                }
            }
        }
    }
}

// 404 page

.cms-index-noroute {
    .note-msg {
        display: none;
    }
}

.page-not-found {
    padding: 40px 20px 30px;

    .media-min(@screen-xxl, {
        padding-left: 0;
        padding-right: 0;
    });

    h1 {
        margin-bottom: 20px;
        font-size: 24px;
        text-transform: uppercase;

        .media-min(@screen-xxs, {
            font-size: 30px;
        });

        .media-min(@screen-m, {
            font-size: 48px;
        });
    }

    h2 {
        font-size: @font-xxl;
    }

    p {
        margin-bottom: 10px;
        line-height: 1.6;

        .media-min(@screen-m, {
            font-size: @font-xs;
        });
    }

    .button {
        .main-btn;
        font-size: @font-m;
    }
}