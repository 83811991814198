.footer-container {
    background: @color-light;

    .footer-top {
        .media-min(@screen-xs, {
            max-width: 1526px;
            margin: 0 auto;
        });

        .footer-menu {
            .media-min(@screen-xs, {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            });

            .media-min(@screen-l, {
                padding: 0 20px;
            });

            .media-min(@screen-xxl, {
                padding: 28px 20px;
            });
        }

        .column {
            .media-min(@screen-xs, {
                width: 50%;
            });

            &:not(.col-newsletter) {
                padding: 0 23px;
                margin-top: 26px;

                .title {
                    margin-bottom: 18px;
                    font-size: @font-xxl;
                    font-weight: @font-weight-bold;
                    text-transform: uppercase;
                }

                .links {
                    a {
                        .link;
                        line-height: 28px;
                        font-size: @font-xs;
                    }
                }
            }
        }

        .col-help {
            .media-min(@screen-l, {
                width: 33.333%;
                order: 1;
            });

            .media-min(@screen-xxl, {
                width: 20%;
                padding-left: 0 !important;
            });
        }

        .col-health {
            .media-min(@screen-l, {
                width: 33.333%;
                order: 2;
            });

            .media-min(@screen-xxl, {
                width: 20%;
            });
        }

        .col-popular {
            .media-min(@screen-l, {
                width: 33.333%;
                order: 3;
            });

            .media-min(@screen-xxl, {
                width: 20%;
            });
        }

        .col-newsletter {
            .media-min(@screen-xs, {
                width: 100%;
            });

            .media-min(@screen-l, {
                width: 50%;
                order: 4;
                margin: 23px 0 0 23px;
            });

            .media-min(@screen-xxl, {
                width: 40%;
                margin: 0;
            });

            .footer-subscribe {
                padding: 27px 19px 32px;
                background: #EBEBEB;

                .media-min(@screen-l, {
                    padding: 30px 100px 30px 45px;
                });

                .title {
                    margin-bottom: 10px;
                    font-size: @font-xxl;
                    font-weight: @font-weight-bold;
                    text-transform: uppercase;
                }

                .description {
                    margin-bottom: 16px;
                    font-size: @font-xs;
                }

                .input-box {
                    display: flex;
                    margin: 0;

                    .input-text {
                        height: 37px;
                        border: none;
                        border-radius: 0;
                        width: calc(~'100% - 115px');

                        .media-min(@screen-l, {
                            border-radius: 555px 0 0 555px;
                        });
                    }

                    .button {
                        padding: 9px 20px;
                        width: 115px;
                        border-radius: 0;

                        .media-min(@screen-l, {
                            border-radius: 0 43px 43px 0;
                            overflow: hidden;
                        });
                    }
                }

                #rcode-footer {
                    margin-top: 5px;
                }
            }

            .desktop-contact {
                display: none;

                .media-min(@screen-xxl, {
                    display: block;
                    margin-top: 20px;
                    padding-left: 45px;

                    .title {
                        margin-bottom: 18px;
                        font-size: @font-xxl;
                        font-weight: @font-weight-bold;
                        text-transform: uppercase;
                    }

                    div {
                        font-size: @font-xs;
                        line-height: 28px;

                        a {
                            .link;
                        }
                    }
                });
            }
        }

        .col-contact {
            .media-min(@screen-l, {
                width: 33.333%;
                order: 5;
            });

            .media-min(@screen-xxl, {
                display: none;
            });

            div {
                font-size: @font-xs;
                line-height: 28px;

                a {
                    .link;
                }
            }
        }
    }

    .footer-bottom {
        margin-top: 42px;
        background: #444444;

        .container {
            display: block;
            padding: 35px 23px 35px;
            text-align: center;

            .media-min(@screen-l, {
                display: flex;
                justify-content: space-between;
                align-items: center;
                max-width: 1526px;
                margin: 0 auto;
                padding-top: 20px;
                padding-bottom: 20px;
            });
        }

        .social-links {
            margin-bottom: 40px;
            font-size: 0;

            .media-min(@screen-l, {
                margin: 0;
            });

            .social-icon {
                display: inline-block;
                position: relative;
                width: 37px;
                height: 37px;
                background: @color-yellow;
                ransition: background .3s ease-in-out;

                .media-min(@screen-l, {
                    border-radius: 8px;
                });

                &:not(:last-child) {
                    margin-right: 10px;
                }

                &::before {
                    .icon;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: @color-black;
                    font-size: 19px;
                    transition: color .3s ease-in-out;
                }

                &:hover {
                    background: @color-black;
                    transition: background .1s ease-in-out;

                    &::before {
                        color: @color-yellow;
                        transition: color .1s ease-in-out;
                    }
                }
            }

            .mail {
                &::before {
                    .icon-mail;
                    font-size: 17px;
                }
            }

            .instagram {
                &::before {
                    .icon-instagram;
                }
            }

            .facebook {
                &::before {
                    .icon-facebook;
                }
            }
            .telegram {
                &::before {
                    .icon-telegram;
                }
            }

            .twitter {
                &::before {
                    .icon-twitter;
                    font-size: 17px;
                }
            }

            .gplus {
                &::before {
                    .icon-1;
                }
            }

            .youtube {
                &::before {
                    .icon-youtube;
                }
            }
        }

        .copyright {
            color: @color-white;
            font-size: @font-xs;

            .media-min(@screen-l, {
                order: -1;
            });
        }
    }
}