.wrapper {
    > .page {
        padding-top: 90px;
    }
}

.header-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background: @color-gray;
    z-index: 10;
}

.header {
    .media-min(@screen-l, {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        max-width: 1480px;
        margin: 0 auto;
    });

    .top-wrap {
        display: flex;
        align-items: center;
    }

    .nav-dropdown-wrap {
        .nav-toggle {
            display: flex;
            align-items: center;
            background: @color-yellow;
            cursor: pointer;
            transition: color .3s ease-in-out, background .3s ease-in-out;

            .media-min(@screen-l, {
                &::before {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 100%;
                    width: 100vw;
                    height: 100vh;
                    transform: translateX(-50%);
                    background: rgba(0, 0, 0, .5);
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                    transition: opacity .1s ease-in-out, visibility .1s ease-in-out;
                }
            });

            .icon {
                position: relative;
                display: block;
                height: 60px;
                width: 68px;

                .media-min(@screen-l, {
                    height: 54px;
                    width: 54px;
                });

                span,
                &::before,
                &::after {
                    position: absolute;
                    left: 17px;
                    width: 34px;
                    height: 3px;
                    background: @color-black;
                    transition: background .3s ease-in-out;

                    .media-min(@screen-l, {
                        left: 15px;
                        width: 22px;
                        height: 2px;
                    });
                }

                span {
                    top: 50%;
                    transform: translateY(-50%);
                }

                &::before {
                    content: '';
                    top: 17px;

                    .media-min(@screen-l, {
                        top: 19px;
                    });
                }

                &::after {
                    content: '';
                    bottom: 17px;
                    width: 22px;
                    transition: background .3s ease-in-out, width .3s ease-in-out;

                    .media-min(@screen-l, {
                        bottom: 19px;
                        width: 13px;
                    });
                }

            }

            .title {
                padding: 12px 20px 12px 0;
                text-transform: uppercase;
                font-size: @font-main;
                font-weight: @font-weight-bold;
                transition: all .3s ease-in-out;

                .media-max(@screen-l, {
                    width: 0;
                    overflow: hidden;
                    padding: 0;
                });
            }

            &:hover {
                .icon {
                    &::after {
                        width: 34px;

                        .media-min(@screen-l, {
                            width: 22px;
                        });
                    }
                }
            }

            &.active {
                background: @color-black;

                .icon {
                    span,
                    &::before,
                    &::after {
                        background: @color-white;
                    }
                }

                .title {
                    color: @color-white;
                }

                .media-min(@screen-l, {
                    &::before {
                        opacity: 1;
                        visibility: visible;
                    }
                });
            }
        }


        .mg-category {
            .media-max(@screen-l, {
                position: fixed;
                top: 0;
                left: -100%;
                width: calc(~'100% - 60px');
                height: 100%;
                transition: transform .3s ease-in-out;
                background: @color-gray;
                z-index: 10;
                display: none;

                &.opened {
                    display: block;
                    transform: translateX(calc(~'100% + 60px'));

                    + .overlay {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            });

            @media (min-width: @screen-l) and (max-height: 839px) {
                position: fixed;
                top: 0;
                left: -420px;
                width: 420px;
                height: 100%;
                transition: transform .3s ease-in-out;
                background: @color-gray;
                z-index: 10;
                display: none;

                &.opened {
                    display: block;
                    transform: translateX(420px);

                    + .overlay {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            //.media-min(@screen-l, {
            //    position: absolute;
            //    top: 100%;
            //    left: 0;
            //    width: 100%;
            //    opacity: 0;
            //    visibility: hidden;
            //    transition: opacity .1s ease-in-out, visibility .1s ease-in-out;
            //    display: none;
            //
            //    &.opened {
            //        display: block;
            //        opacity: 1;
            //        visibility: visible;
            //    }
            //});

            @media (min-width: @screen-l) and (min-height: 840px) {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                opacity: 0;
                visibility: hidden;
                transition: opacity .1s ease-in-out, visibility .1s ease-in-out;
                display: none;

                &.opened {
                    display: block;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            z-index: 9;
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s ease-in-out, visibility .3s ease-in-out, background .3s ease-in-out;
            cursor: pointer;

            .media-max(@screen-l, {
                &::before {
                    .icon;
                    .icon-cross;
                    position: absolute;
                    top: 18px;
                    right: 18px;
                    color: @color-white;
                    font-size: 25px;
                }
            });

            @media (min-width: @screen-l) and (max-height: 839px) {
                &::before {
                    .icon;
                    .icon-cross;
                    position: absolute;
                    top: 18px;
                    right: 18px;
                    color: @color-white;
                    font-size: 25px;
                }
            }


            //.media-min(@screen-l, {
            //    z-index: -1;
            //});

            @media (min-width: @screen-l) and (min-height: 840px) {
                z-index: -1;
            }

            &:hover {
                background: rgba(0, 0, 0, 0.3);
            }
        }
    }

    .logo {
        max-width: 104px;
        margin: 0 auto;

        .media-min(@screen-l, {
            order: -1;
            margin: 0 48px 0 0;
            max-width: 120px;
        });

        strong {
            .hidden;
        }

        img {
            width: 100%;
        }
    }

    .nav-container {
        ul {
            display: flex;
            flex-wrap: wrap;
            padding-left: 15px;

            li {
                &.steroids-on-sale {
                    a {
                        position: relative;
                        padding-left: 40px;
                        text-transform: uppercase;

                        &::before {
                            .icon;
                            .icon-sale;
                            position: absolute;
                            top: 50%;
                            left: 15px;
                            transform: translateY(-50%);
                            margin-right: 7px;
                            color: @color-yellow;
                            font-size: 18px;
                        }
                    }
                }

                &.oral-steroids {
                    @media screen and (max-width: 1100px) {
                        display: none;
                    }
                }

                &.steroids-on-sale {
                    @media screen and (max-width: 1180px) {
                        display: none;
                    }
                }

                &.weight-loss {
                    @media screen and (max-width: 1280px) {
                        display: none;
                    }
                }

                &.sexual-health {
                    @media screen and (max-width: 1370px) {
                        display: none;
                    }
                }

                &.sarms {
                    @media screen and (max-width: 1440px) {
                        display: none;
                    }
                }

                a {
                    display: flex;
                    padding: 15px 13px;
                    color: @color-white;
                    font-size: @font-xxs;
                    font-weight: @font-weight-bold;
                    text-decoration: none;
                    transition: color .3s ease-in-out;

                    &:hover {
                        color: @color-yellow;
                    }
                }
            }
        }
    }

    .nav-search-container {
        .media-min(@screen-l, {
            min-width: 260px;
            margin-left: auto;
            margin-right: 12px;
        });

        .search-btn {
            position: relative;
            height: 60px;
            width: 60px;
            cursor: pointer;
            transition: background .3s ease-in-out;

            .media-min(@screen-l, {
                display: none;
            });

            &::before {
                .icon;
                .icon-search;
                position: absolute;
                top: 50%;
                left: 50%;
                color: @color-white;
                font-size: 20px;
                transform: translate(-50%, -50%);
                transition: color .3s ease-in-out;
            }

            &:hover {
                background: @color-yellow;

                &::before {
                    color: @color-black;
                }
            }
        }

        .search-wrap {
            transition: none;

            .media-max(@screen-l, {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: block !important;
                opacity: 0;
                visibility: hidden;
                background: rgba(0, 0, 0, 0.5);
                backdrop-filter: blur(12px);
                z-index: 10;
                transition: opacity .3s, visibility .3s;

                &.opened {
                    opacity: 1;
                    visibility: visible;
                }
            });

            .form-search {
                .media-max(@screen-l, {
                    padding: 0 12px;
                    text-align: center;
                });

                > label {
                    display: inline-block;
                    padding: 15px 0 20px;
                    color: @color-white;
                    font-size: @font-xl;
                    font-weight: @font-weight-bold;

                    .media-min(@screen-l, {
                        display: none;
                    });
                }

                .search-close-btn {
                    position: absolute;
                    top: 18px;
                    right: 18px;
                    cursor: pointer;

                    .media-min(@screen-l, {
                        display: none;
                    });

                    &::before {
                        .icon;
                        .icon-cross;
                        color: @color-white;
                        font-size: 25px;
                    }
                }

                .input-box {
                    position: relative;
                    margin: 0;

                    input {
                        height: 48px;
                        padding: 13px 70px 13px 18px;
                        border-radius: 9px;
                        border: none;
                        background: @color-white;

                        &.loading {
                            background: @color-white url(../images/amxsearch_loader.gif) no-repeat 95% center !important;
                        }

                        .media-min(@screen-l, {
                            height: 38px;
                            border-radius: 43px;
                        });
                    }

                    button {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 52px;
                        height: 100%;
                        font-size: 0;

                        &::before {
                            .icon;
                            .icon-search;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            color: @color-black;
                            transform: translate(-50%, -50%);
                            font-size: 20px;
                        }
                    }
                }

                .search-autocomplete {
                    display: none !important;
                }
            }

            .am_search_popup {
                width: 100%;
                margin-top: 10px;
                border: none;
                box-shadow: none;

                .media-min(@screen-l, {
                    margin-top: 8px;
                    width: 450px;
                });
            }

            .am_search_popup_inner {
                max-height: calc(~'100vh - 120px');
                padding: 0 12px 20px;
                overflow-y: auto;
                background: transparent;
                border: none;

                .media-min(@screen-l, {
                    padding: 12px;
                    background: rgba(0,0,0,.2);
                    backdrop-filter: blur(6px);
                    border-radius: 0 0 12px 12px;
                });

                .am-products {
                    padding: 0;
                    background: transparent;

                    .am-container-title {
                        display: none;
                    }

                    .am-product-link {
                        display: block;
                        margin-bottom: 10px;
                    }

                    .am_element {
                        display: flex;
                        padding: 8px;
                        border-radius: 12px;
                        transition: box-shadow .3s ease-in-out;

                        &:hover {
                            background: @color-white;
                            box-shadow: 0 0 12px rgba(0,0,0,.25);
                        }

                        .am_image {
                            width: 50px;
                            padding: 0;
                            border: none;

                            > img {
                                border: none;
                                border-radius: 8px;
                            }
                        }

                        .am_right {
                            width: calc(~'100% - 50px');
                            padding-left: 22px;
                            font-size: 0;
                            overflow: hidden;

                            .am_title {
                                max-height: 56px;
                                padding: 0;
                                font-size: 14px;
                                font-weight: @font-weight-bold;
                                white-space: normal;
                                text-overflow: ellipsis;
                                line-height: 2;
                            }

                            .price_footer {
                                display: none;
                            }
                        }
                    }
                }

                .am-more-results {
                    .more_results {
                        border-radius: 12px;
                        font-size: 14px;
                        transition: box-shadow .3s ease-in-out;

                        &:hover {
                            box-shadow: 0 0 12px rgba(0,0,0,.25);
                        }
                    }
                }
            }
        }
    }

    .header-cart {
        position: relative;
        height: 60px;
        width: 60px;
        background: @color-yellow;
        text-align: center;
        cursor: pointer;
        transition: background .3s ease-in-out;

        .media-min(@screen-l, {
            height: 54px;
            padding-left: 55px;
            width: auto;
            min-width: 174px;
        });

        &::before {
            .icon;
            .icon-cart;
            position: absolute;
            top: 50%;
            left: 17px;
            font-size: 22px;
            color: @color-black;
            transform: translateY(-50%);
            transition: color .3s ease-in-out;
        }

        .qty-mobile {
            font-size: @font-xxxs;
            font-weight: @font-weight-bold;

            .media-min(@screen-l, {
                display: none;
            });
        }

        .header-switch-trigger {
            .media-max(@screen-l, {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                font-size: 0;
            });

            .media-min(@screen-l, {
                display: flex;
                align-items: center;
                height: 100%;
                color: @color-black;
                font-weight: @font-weight-bold;
                text-decoration: none;
                text-transform: uppercase;
                font-size: @font-main;
            });

            .qty {
                .media-min(@screen-l, {
                    padding-left: 15px;

                    &::before {
                        content: '|';
                        display: inline-block;
                        margin-right: 16px;
                        vertical-align: top;
                        font-size: @font-xxs;
                        font-weight: @font-weight-regular;
                    };
                });
            }
        }

        .header-dropdown {
            position: absolute;
            top: 100%;
            right: 0;
            width: 315px;
            padding: 40px 50px;
            background: @color-white;
            border-radius: 6px;
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
            text-align: center;
            font-size: 21px;
            opacity: 0;
            visibility: hidden;
            cursor: default;
            transition: opacity .1s ease-in-out, visibility .1s ease-in-out;

            .block-subtitle {
                margin-bottom: 10px;
            }

            .a-center {
                font-size: @font-main;
            }

            .buttons {
                margin-top: 30px;

                .main-btn {
                    display: block;
                    width: 100%;
                    height: 46px;
                    overflow: hidden;
                    font-size: @font-m;
                    margin-bottom: 18px;
                    border-radius: 45px;

                    &::before {
                        .icon;
                        .icon-arrow-right;
                        position: absolute;
                        top: 50%;
                        right: 28px;
                        transform: translateY(-50%);
                        font-size: 14px;
                        font-weight: @font-weight-bold;
                        z-index: 1;
                    }

                    &:hover {
                        &::before {
                            color: @color-white;
                        }
                    }
                }

                .btn-continue {
                    font-size: @font-m;
                    transition: color .3s ease-in-out;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        &:hover {
            .header-dropdown {
                .media-min(@screen-l, {
                    opacity: 1;
                    visibility: visible;
                });
            }
        }
    }

    .bottom-links-wrap {
        background: @color-main;

        .media-min(@screen-l, {
            position: relative;
            display: flex;
            justify-content: space-between;

            &::before {
                content: '';
                position: absolute;
                left: 50%;
                top: 0;
                bottom: 0;
                width: 100vw;
                transform: translateX(-50%);
                background: @color-main;
                z-index: -1;
            }
        });

        .text {
            .media-max(@screen-l, {
                display: none;
            });

            .media-min(@screen-l, {
                display: flex;
                align-items: center;
                color: @color-white;
                font-weight: @font-weight-bold;

                img {
                    max-width: 30px;
                    margin-right: 10px;
                }
            });
        }

        .trustpilot-widget {
            .media-max(@screen-l, {
                display: none;
            });

            a {
                .link;
                display: block;
                padding: 6px 9px;
                color: @color-white;
                font-size: @font-xxs;
                font-weight: @font-weight-bold;

                .media-min(@screen-l, {
                    padding: 6px 9px;
                });
            }
        }

        .links {
            overflow: hidden;
            font-size: 0;

            li {
                display: inline-block;

                &#reviews {
                    .media-max(@screen-l, {
                        display: none;
                    });
                }

                &#top-link-login {
                    a {
                        .media-min(@screen-l, {
                            position: relative;
                            padding-right: 39px;

                            &::before {
                                .icon;
                                .icon-login;
                                position: absolute;
                                top: 50%;
                                right: 2px;
                                margin-left: 18px;
                                font-size: 19px;
                                transform: translateY(-50%);
                            }
                        });
                    }
                }

                a {
                    .link;
                    display: block;
                    padding: 6px 10px;
                    color: @color-white;
                    font-size: @font-xxs;
                    font-weight: @font-weight-bold;
                }
            }
        }
    }

    // Category

    .mg-category {
        .categories {
            .top-mobile {
                @media (min-width: @screen-l) and (min-height: 840px) {
                    display: none;
                }
            }

            .category-list {
                @media (min-width: @screen-l) and (max-height: 839px) {
                    padding-top: 10px;
                    overflow: hidden;
                    overflow-y: auto;
                    height: calc(~'100vh - 78px');
                }

                @media (min-width: @screen-l) and (min-height: 840px) {
                    padding: 37px 0 30px;
                    max-width: 338px;
                    background: @color-black;
                }
            }

            .level-0 {
                > a {
                    @media (min-width: @screen-xxxl) and (min-height: 840px) {
                        padding: 12px 44px 12px 60px;
                        font-size: @font-m;
                    }

                    &::before {
                        @media (min-width: @screen-xxxl) and (min-height: 840px) {
                            width: 27px;
                            height: 35px;
                        }
                    }

                    &:hover {
                        @media (min-width: @screen-l) and (min-height: 840px) {
                            background: @color-white;
                            color: @color-black;
                        }
                    }
                }

                &.parent {
                    > a {
                        @media (min-width: @screen-l) and (max-height: 839px) {
                            &::after {
                                .icon;
                                .icon-arrow-right;
                                position: absolute;
                                top: 50%;
                                right: 36px;
                                transform: translateY(-50%);
                                color: @color-white;
                                font-size: 11px;
                            }
                        }
                    }
                }

                .submenu {
                    @media (min-width: @screen-l) and (max-height: 839px) {
                        position: fixed;
                        top: 0;
                        left: -100%;
                        width: 100%;
                        height: 100%;
                        transition: transform .3s ease-in-out;
                        background: @color-gray;
                        z-index: 10;
                        display: none;
                    }

                    @media (min-width: @screen-l) and (min-height: 840px) {
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: calc(~'100% - 338px');
                        height: 100%;
                        opacity: 0;
                        visibility: hidden;
                        display: none;
                        transition: opacity .1s ease-in-out, visibility .1s ease-in-out;
                    }

                    .content-wrap {
                        @media (min-width: @screen-l) and (max-height: 839px) {
                            height: calc(~'100vh - 68px');
                            padding: 22px 0;
                            overflow: hidden;
                            overflow-y: auto;
                        }

                        @media (min-width: @screen-l) and (min-height: 840px) {
                            display: flex;
                            height: 100%;
                            padding: 45px 20px 45px 0;
                        }

                        @media (min-width: @screen-xxl) and (min-height: 840px) {
                            padding: 45px 70px 45px 20px;
                        }
                    }

                    .half {
                        @media (min-width: @screen-l) and (min-height: 840px) {
                            width: 493px;
                            overflow: hidden;
                        }

                        &.right {
                            @media (min-width: @screen-l) and (min-height: 840px) {
                                width: calc(~'100% - 493px');
                                padding-left: 20px;
                            }

                            @media (min-width: @screen-xxl) and (min-height: 840px) {
                                padding-left: 40px;
                            }
                        }

                        .category-name {
                            @media (min-width: @screen-l) and (max-height: 839px) {
                                display: none;
                            }

                            @media (min-width: @screen-l) and (min-height: 840px) {
                                padding-left: 30px;
                            }

                            @media (min-width: @screen-xxl) and (min-height: 840px) {
                                padding-left: 50px;
                            }
                        }

                        .category-name,
                        .brands > .title,
                        .bestsellers > .title {
                            @media (min-width: @screen-l) and (min-height: 840px) {
                                color: @color-black;
                                margin-bottom: 36px;
                                font-size: @font-xxl;
                                font-weight: @font-weight-bold;
                            }
                        }

                        .sub-list {
                            @media (min-width: @screen-l) and (min-height: 840px) {
                                display: flex;
                                height: 100%;
                            }

                            &.two {
                                .level-1 {
                                    @media (min-width: @screen-l) and (min-height: 840px) {
                                        width: 50%;
                                    }
                                }
                            }

                            .level-1 {
                                @media (min-width: @screen-l) and (min-height: 840px) {
                                    width: 100%;
                                    padding: 0 20px 0 20px;
                                    border-right: 1px solid #dbdbdb;
                                }

                                @media (min-width: @screen-xxl) and (min-height: 840px) {
                                    padding: 0 20px 0 40px;
                                }

                                li {
                                    a {
                                        @media (min-width: @screen-l) and (min-height: 840px) {
                                            display: inline-block;
                                            color: @color-black;
                                            .link;
                                            padding: 5px 10px;
                                        }
                                    }

                                    &:not(.sub-name) {
                                        a {
                                            @media (min-width: @screen-l) and (min-height: 840px) {
                                                padding: 5px 10px;
                                            }
                                        }
                                    }

                                    &:not(:last-child) {
                                        @media (min-width: @screen-l) and (min-height: 840px) {
                                            margin-bottom: 16px;
                                        }
                                    }

                                    &.sub-name {
                                        a {
                                            @media (min-width: @screen-l) and (min-height: 840px) {
                                                padding-top: 0;
                                                margin-bottom: 20px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.right {
                            .title {
                                @media (min-width: @screen-l) and (max-height: 839px) {
                                    padding: 14px 18px;
                                    color: @color-white;
                                    font-size: @font-m;
                                    font-weight: @font-weight-bold;
                                }
                            }
                        }

                        .brands-list {
                            .item {
                                &:not(:last-child) {
                                    @media (min-width: @screen-l) and (min-height: 840px) {
                                        padding-bottom: 20px;
                                    }
                                }
                            }
                        }

                        .bestsellers {
                            .items {
                                @media (min-width: @screen-l) and (max-height: 839px) {
                                    padding: 14px 40px;
                                }

                                @media (min-width: @screen-l) and (min-height: 840px) {
                                    justify-content: center;
                                    margin: 0;
                                }

                                @media (min-width: @screen-xl) and (min-height: 840px) {
                                    justify-content: center;
                                    margin-top: 100px;
                                }
                            }

                            .item {
                                @media (min-width: @screen-l) and (min-height: 840px) {
                                    width: 100%;
                                    padding: 0;
                                }

                                @media (min-width: @screen-xl) and (min-height: 840px) {
                                    width: 50%;
                                    padding: 0 17px;
                                }

                                .product-name {
                                    @media (min-width: @screen-l) and (max-height: 839px) {
                                        color: @color-white;
                                    }
                                }

                                .price-box {
                                    .price {
                                        @media (min-width: @screen-l) and (max-height: 839px) {
                                            color: @color-white;
                                        }
                                    }

                                    .old-price {
                                        .price {
                                            @media (min-width: @screen-l) and (max-height: 839px) {
                                                color: @color-white;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.active {
                    > a {
                        @media (min-width: @screen-l) and (min-height: 840px) {
                            background: @color-white;
                            color: @color-black;
                        }
                    }

                    .submenu {
                        @media (min-width: @screen-l) and (max-height: 839px) {
                            transform: translateX(100%);
                            display: block;
                        }

                        @media (min-width: @screen-l) and (min-height: 840px) {
                            opacity: 1;
                            visibility: visible;
                            display: block;
                        }
                    }
                }
            }
        }

        .item.view-all {
            @media (min-width: @screen-l) and (max-height: 839px) {
                padding-bottom: 18px;
            }

            .main-btn {
                @media (min-width: @screen-l) and (max-height: 839px) {
                    &::after {
                        background: @color-gray;
                    }
                }
            }
        }
    }
}

// Category

.mg-category {
    background: @color-white;
    z-index: 10;

    .categories {
        .top-mobile {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 68px;
            padding: 10px 18px;
            color: @color-white;
            border-bottom: 1px solid #3A3A3A;

            .title {
                font-size: @font-xxl;
                font-weight: @font-weight-bold;
            }

            .category-name {
                display: inline-block;
                margin-right: 12px;
                color: @color-white;
                font-size: @font-m;
                font-weight: @font-weight-bold;
                text-decoration: none;
            }

            .back-btn {
                //height: 100%;
                font-size: @font-m;
                cursor: pointer;

                &::before {
                    .icon;
                    .icon-arrow-left;
                    margin-right: 7px;
                    font-size: 11px;
                }
            }
        }

        .category-list {
            .media-max(@screen-l, {
                padding-top: 10px;
                overflow: hidden;
                overflow-y: auto;
                height: calc(~'100vh - 78px');
            });
        }

        .level-0 {
            > a {
                position: relative;
                display: block;
                padding: 8px 44px 8px 60px;
                font-size: @font-xs;
                color: @color-white;
                font-weight: @font-weight-bold;
                text-decoration: none;
                transition: color .3s ease-in-out, background .3s ease-in-out;

                &::before {
                    content: '';
                    position: absolute;
                    left: 30px;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 22px;
                    height: 30px;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                }

                &.injectable-steroids {
                    &::before {
                        background-image: url("../images/menu/injectable-steroids.png");
                    }
                }

                &.oral-steroids {
                    &::before {
                        background-image: url("../images/menu/oral-steroids.png");
                    }
                }

                &.steroids-on-sale {
                    &::before {
                        font-weight: 400;
                        content: "\e91a";
                        position: absolute;
                        top: 50%;
                        left: 15px;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                        margin-right: 7px;
                        color: #FFE51E;
                        font-size: 28px;
                        width: 28px;
                        height: 28px;
                        font-family: mg-icons;
                    }
                }

                &.weight-loss {
                    &::before {
                        background-image: url("../images/menu/weight-loss.svg");
                    }
                }

                &.pre-designed-cycle {
                    &::before {
                        background-image: url("../images/menu/pre-designed-cycle.svg");
                    }
                }

                &.post-cycle-therapy {
                    &::before {
                        background-image: url("../images/menu/post-cycle-therapy.svg");
                    }
                }

                &.sexual-health {
                    &::before {
                        background-image: url("../images/menu/sex-health.svg");
                        background-size: 22px;
                    }
                }

                &.sarms {
                    &::before {
                        background-image: url("../images/menu/sarms.svg");
                    }
                }

                &.pharmacy-grade {
                    &::before {
                        background-image: url("../images/menu/pharmacy-grade.svg");
                        background-size: 22px;
                    }
                }

                &.hair-skin-sleep {
                    &::before {
                        background-image: url("../images/menu/hair-skin-sleep.svg");
                    }
                }

                &.syringes-accessories,
                &.syringes-needles {
                    &::before {
                        background-image: url("../images/menu/syringes-accessories.svg");
                    }
                }

                &.hgh-and-peptides {
                    &::before {
                        background-image: url("../images/menu/hgh-eptides.svg");
                    }
                }

                &.smart-drugs {
                    &::before {
                        background-image: url("../images/menu/smart-drugs.svg");
                    }
                }

                &.anti-aging {
                    &::before {
                        background-image: url("../images/menu/anti-aging.svg");
                    }
                }

                &.for-women {
                    &::before {
                        background-image: url("../images/menu/for-women.svg");
                    }
                }

                &.diuretics-liver-heart {
                    &::before {
                        background-image: url("../images/menu/diuretics-liver-heart.svg");
                    }
                }
            }

            &.parent {
                > a {
                    .media-max(@screen-l, {
                        &::after {
                            .icon;
                            .icon-arrow-right;
                            position: absolute;
                            top: 50%;
                            right: 36px;
                            transform: translateY(-50%);
                            color: @color-white;
                            font-size: 11px;
                        }
                    });
                }
            }

            .submenu {
                .media-max(@screen-l, {
                    position: fixed;
                    top: 0;
                    left: -100%;
                    width: 100%;
                    height: 100%;
                    transition: transform .3s ease-in-out;
                    background: @color-gray;
                    z-index: 10;
                    display: none;
                });

                .content-wrap {
                    .media-max(@screen-l, {
                        height: calc(~'100vh - 68px');
                        padding: 22px 0;
                        overflow: hidden;
                        overflow-y: auto;
                    });
                }

                .half {
                    .category-name {
                        .media-max(@screen-l, {
                            display: none;
                        });
                    }

                    .sub-list {
                        .level-1 {
                            li {
                                a {
                                    display: block;
                                    padding: 14px 18px;
                                    color: @color-white;
                                    text-decoration: none;
                                    font-size: @font-m;
                                }

                                &:not(.sub-name) {
                                    a {
                                        padding: 14px 40px;
                                    }
                                }

                                &.sub-name {
                                    a {
                                        font-weight: @font-weight-bold;
                                    }
                                }

                                &.shop-all {
                                    a {
                                        font-size: @font-main;
                                        font-weight: @font-weight-bold;
                                    }
                                }
                            }
                        }
                    }

                    &.right {
                        .title {
                            .media-max(@screen-l, {
                                padding: 14px 18px;
                                color: @color-white;
                                font-size: @font-m;
                                font-weight: @font-weight-bold;
                            });
                        }
                    }

                    .brands-list {
                        font-size: 0;

                        .item {
                            &:not(:last-child) {
                                padding-bottom: 13px;
                            }

                            a {
                                display: block;
                                transition: box-shadow .3s ease-in-out;

                                &:hover {
                                    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.15);
                                }
                            }

                            img {
                                width: 100%;
                            }
                        }
                    }

                    .bestsellers {
                        .items {
                            .media-max(@screen-l, {
                                padding: 14px 40px;
                            });

                            .media-min(@screen-xs, {
                                display: flex;
                                flex-wrap: wrap;
                                margin: 0 -6px;
                            });

                        }

                        .item {
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 20px;

                            .media-min(@screen-xs, {
                                width: 50%;
                                max-width: 240px;
                                padding: 0 6px;
                            });

                            .product-name {
                                margin: 10px 0;
                                padding: 0 8px;
                                color: @color-black;
                                font-weight: @font-weight-bold;
                                text-decoration: none;

                                .media-max(@screen-l, {
                                    color: @color-white;
                                });
                            }

                            .price-box {
                                margin: auto 0 10px;
                                padding: 0 8px;

                                .price {
                                    .media-max(@screen-l, {
                                        color: @color-white;
                                    });

                                    .media-min(@screen-m, {
                                        font-size: @font-xxl;
                                    });
                                }

                                .old-price {
                                    .price {
                                        .media-max(@screen-l, {
                                            color: @color-white;
                                        });

                                        .media-min(@screen-m, {
                                            font-size: @font-xxs;
                                        });
                                    }
                                }
                            }

                            .btn-cart {
                                width: 100%;
                                border-radius: 0;

                                .media-max(@screen-m, {
                                    padding: 10px 20px;
                                });

                                .media-min(@screen-m, {
                                    padding: 16px 48px 16px 20px;
                                });
                            }
                        }
                    }
                }
            }

            &.active {
                .submenu {
                    .media-max(@screen-l, {
                        transform: translateX(100%);
                        display: block;
                    });
                }
            }
        }
    }

    .item.view-all {
        padding-top: 18px;
        text-align: center;

        .media-max(@screen-l, {
            padding-bottom: 18px;
        });

        .main-btn {
            min-width: 163px;
            border: 1px solid @color-yellow;
            border-radius: 33px;
            overflow: hidden;
            text-decoration: none;
            text-transform: uppercase;

            .media-max(@screen-l, {
                &::after {
                    background: @color-gray;
                }
            });
        }
    }
}

// Category

.hp-categories-block {
    .mg-category {
        .categories {
            .top-mobile {
                .media-min(@screen-l, {
                    display: none;
                });
            }

            .category-list {
                .media-min(@screen-l, {
                    padding: 37px 0 30px;
                    max-width: 338px;
                    background: @color-black;
                });
            }

            .level-0 {
                > a {
                    .media-min(@screen-xxxl, {
                        padding: 12px 44px 12px 60px;
                        font-size: @font-m;
                    });

                    &::before {
                        .media-min(@screen-xxxl, {
                            width: 27px;
                            height: 35px;
                        });
                    }

                    &:hover {
                        .media-min(@screen-l, {
                            background: @color-white;
                            color: @color-black;
                        });
                    }
                }

                .submenu {
                    .media-min(@screen-l, {
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: calc(~'100% - 338px');
                        height: 100%;
                        opacity: 0;
                        visibility: hidden;
                        display: none;
                        transition: opacity .1s ease-in-out, visibility .1s ease-in-out;
                    });

                    .content-wrap {
                        .media-min(@screen-l, {
                            display: flex;
                            height: 100%;
                            padding: 45px 20px 45px 0;
                        });

                        .media-min(@screen-xxl, {
                            padding: 45px 70px 45px 20px;
                        });
                    }

                    .half {
                        .media-min(@screen-l, {
                            width: 493px;
                            overflow: hidden;
                        });

                        &.right {
                            .media-min(@screen-l, {
                                width: calc(~'100% - 493px');
                                padding-left: 20px;
                            });

                            .media-min(@screen-xxl, {
                                padding-left: 40px;
                            });
                        }

                        .category-name {
                            .media-min(@screen-l, {
                                padding-left: 30px;
                            });

                            .media-min(@screen-xxl, {
                                padding-left: 50px;
                            });
                        }

                        .category-name,
                        .brands > .title,
                        .bestsellers > .title {
                            .media-min(@screen-l, {
                                color: @color-black;
                                margin-bottom: 36px;
                                font-size: @font-xxl;
                                font-weight: @font-weight-bold;
                            });
                        }

                        .sub-list {
                            .media-min(@screen-l, {
                                display: flex;
                                height: 100%;
                            });

                            &.two {
                                .level-1 {
                                    .media-min(@screen-l, {
                                        width: 50%;
                                    });
                                }
                            }

                            .level-1 {
                                .media-min(@screen-l, {
                                    width: 100%;
                                    padding: 0 20px 0 20px;
                                    border-right: 1px solid #dbdbdb;
                                });

                                .media-min(@screen-xxl, {
                                    padding: 0 20px 0 40px;
                                });

                                li {
                                    a {
                                        .media-min(@screen-l, {
                                            display: inline-block;
                                            color: @color-black;
                                            .link;
                                            padding: 5px 10px;
                                        });
                                    }

                                    &:not(.sub-name) {
                                        a {
                                            .media-min(@screen-l, {
                                                padding: 5px 10px;
                                            });
                                        }
                                    }

                                    &:not(:last-child) {
                                        .media-min(@screen-l, {
                                            margin-bottom: 16px;
                                        });
                                    }

                                    &.sub-name {
                                        a {
                                            .media-min(@screen-l, {
                                                padding-top: 0;
                                                margin-bottom: 20px;
                                            });
                                        }
                                    }
                                }
                            }
                        }

                        .brands-list {
                            .item {
                                &:not(:last-child) {
                                    .media-min(@screen-l, {
                                        padding-bottom: 20px;
                                    });
                                }
                            }
                        }

                        .bestsellers {
                            .items {
                                .media-min(@screen-xs, {
                                    display: flex;
                                    flex-wrap: wrap;
                                    margin: 0 -6px;
                                });

                                .media-min(@screen-l, {
                                    justify-content: center;
                                    margin: 0;
                                });

                                .media-min(@screen-xl, {
                                    justify-content: center;
                                    margin-top: 100px;
                                });
                            }

                            .item {
                                .media-min(@screen-xs, {
                                    width: 50%;
                                    max-width: 240px;
                                    padding: 0 6px;
                                });

                                .media-min(@screen-l, {
                                    width: 100%;
                                    padding: 0;
                                });

                                .media-min(@screen-xl, {
                                    width: 50%;
                                    padding: 0 17px;
                                });

                                .price-box {
                                    .price {
                                        .media-min(@screen-m, {
                                            font-size: @font-xxl;
                                        });
                                    }

                                    .old-price {
                                        .price {
                                            .media-min(@screen-m, {
                                                font-size: @font-xxs;
                                            });
                                        }
                                    }
                                }

                                .btn-cart {
                                    .media-min(@screen-m, {
                                        padding: 16px 48px 16px 20px;
                                    });
                                }
                            }
                        }
                    }
                }

                &.active {
                    > a {
                        .media-min(@screen-l, {
                            background: @color-white;
                            color: @color-black;
                        });
                    }

                    .submenu {
                        .media-min(@screen-l, {
                            opacity: 1;
                            visibility: visible;
                            display: block;
                        });
                    }
                }
            }
        }
    }
}
