.lib-font-face(
    @family-name,
    @font-path,
    @font-weight: normal,
    @font-style: normal
) {
    @font-face {
        font-family: @family-name;
        src: url('@{font-path}.woff2') format('woff2'),
        url('@{font-path}.woff') format('woff');
        font-weight: @font-weight;
        font-style: @font-style;
    }
}

.lib-font-face(
    @family-name: @font-family__base,
    @font-path: '../fonts/open-sans/OpenSans-Light',
    @font-weight: @font-weight-light,
    @font-style: normal
);

.lib-font-face(
    @family-name: @font-family__base,
    @font-path: '../fonts/open-sans/OpenSans-LightItalic',
    @font-weight: @font-weight-light,
    @font-style: italic
);

.lib-font-face(
    @family-name: @font-family__base,
    @font-path: '../fonts/open-sans/OpenSans-Regular',
    @font-weight: @font-weight-regular,
    @font-style: normal
);

.lib-font-face(
    @family-name: @font-family__base,
    @font-path: '../fonts/open-sans/OpenSans-Italic',
    @font-weight: @font-weight-regular,
    @font-style: italic
);

.lib-font-face(
    @family-name: @font-family__base,
    @font-path: '../fonts/open-sans/OpenSans-SemiBold',
    @font-weight: @font-weight-semibold,
    @font-style: normal
);

.lib-font-face(
    @family-name: @font-family__base,
    @font-path: '../fonts/open-sans/OpenSans-SemiBoldItalic',
    @font-weight: @font-weight-semibold,
    @font-style: italic
);

.lib-font-face(
    @family-name: @font-family__base,
    @font-path: '../fonts/open-sans/OpenSans-Bold',
    @font-weight: @font-weight-bold,
    @font-style: normal
);

.lib-font-face(
    @family-name: @font-family__base,
    @font-path: '../fonts/open-sans/OpenSans-BoldItalic',
    @font-weight: @font-weight-bold,
    @font-style: italic
);